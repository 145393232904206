/* Custom styles for the DatePicker */
.date-picker-wrapper {
  display: block;
  width: 100%;
}

.datepicker-full-width {
  width: 100% !important;
}

/* Override react-datepicker's default styles to ensure full width */
.react-datepicker-wrapper {
  width: 100% !important;
  display: block !important;
}

.react-datepicker__input-container {
  width: 100% !important;
  display: block !important;
}

.react-datepicker__input-container input {
  width: 100% !important;
  display: block !important;
}
