.container {
	z-index: 100;

	/* opacity: 0.4; */
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 20px;
}

.container>span {
	color: #000;
}

.loader {
	border: 3px solid #fff;
	border-top: 3px solid var(--primary);
	border-radius: 50%;
	width: 30px;
	height: 30px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}