.modalTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 12px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ECECEC;
}

.modalTitleContainer > button {
  font-size: 20px;
  border: none;
  transition: color 0.5s;
}

.modalTitleContainer > button:hover {
  cursor: pointer;
  color: red;
}

@media (max-width: 800px) {
  .modalTitleContainer > h1 {
    font-size: 1.3rem;
  }
}
